<template>
  <div>
    <div class="hp">
      <BannersFullwidthBanner v-if="homeBanner" :banner="homeBanner" :lazy="false" class="u-m-t-sm home-banner" />
      <BannersReinsuranceBanner />
      <CarouselProductsCarousel
        :lazy="true"
        :references="featuredProducts"
        :count="featuredProducts.length"
        :horizontal-cell="true"
        translate-key="featuredProducts"
        class="home-padding-top height-cls-desktop-featureds"
        element-test="featured-products"
        :fast-add-to-cart="true"
        origin-for-tracking="carousel_featuredProducts"
      />
      <CarouselFeaturedSellersCarousel
        v-if="featuredSellers?.length"
        :lazy="false"
        :stories="featuredSellers"
        element-test="featured-sellers"
        class="home-padding-top"
      />
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCarouselProductsCarousel">
        <LazyCarouselProductsCarousel
          v-if="isVisible"
          :references="bestSellersReferences"
          :count="bestSellersCount"
          :featured-cell="true"
          link="best-sellers"
          translate-key="bestSellers"
          class="home-padding-top"
          element-test="featured-best-sellers"
          :fast-add-to-cart="true"
          origin-for-tracking="carousel_bestSellersProducts"
        />
      </ComponentLazy>
      <!-- <FeaturedInspirationsCarousel
        v-if="featuredInspirations?.length"
        :stories="featuredInspirations"
        class="home-padding-top"
      /> -->
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCarouselFeaturedSeasonalSelectionsCarousel">
        <LazyCarouselFeaturedSeasonalSelectionsCarousel
          v-if="featuredSeasonalSelections?.length && isVisible"
          :stories="featuredSeasonalSelections"
          :main-story="featuredSeasonalSelectionsMenu"
          element-test="featured-seasonal"
          class="home-padding-top"
        />
      </ComponentLazy>
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCarouselProductsCarousel">
        <LazyCarouselProductsCarousel
          v-if="isVisible"
          :references="newProductsReferences"
          :count="newProductsCount"
          :horizontal-cell="true"
          link="new-products"
          translate-key="newProducts"
          class="home-padding-top"
          element-test="featured-new-products"
          :fast-add-to-cart="true"
        />
      </ComponentLazy>
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCounters">
        <LazyCounters v-if="isVisible" class="home-padding-top" element-test="counters" />
      </ComponentLazy>
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCarouselFeaturedBrandsCarousel">
        <LazyCarouselFeaturedBrandsCarousel
          v-if="featuredBrands?.length && isVisible"
          :title="$t('home.topBrands')"
          :brands="featuredBrands"
          element-test="featured-brands"
          class="home-padding-top"
        />
      </ComponentLazy>
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCarouselTrustpilotCarousel">
        <LazyCarouselTrustpilotCarousel
          v-show="tld === 'FR'"
          v-if="isVisible"
          class="home-padding-top"
          element-test="featured-trustpilot"
        />
      </ComponentLazy>
      <ComponentLazy v-if="seoContent1" v-slot="{ isVisible }" ref-name="homeSbContent">
        <LazySbContent
          v-if="seoContent1 && isVisible"
          :content="seoContent1"
          class="home-padding-top"
          element-test="seo-content-1"
        />
      </ComponentLazy>
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeCarouselNewsCarousel">
        <LazyCarouselNewsCarousel v-if="news?.length && isVisible" :news="news" element-test="featured-news" />
      </ComponentLazy>
      <ComponentLazy v-if="seoContent2" v-slot="{ isVisible }" ref-name="homeSbContent2">
        <LazySbContent
          v-if="seoContent2 && isVisible"
          :content="seoContent2"
          class="home-padding-top"
          element-test="seo-content-2"
        />
      </ComponentLazy>
      <ComponentLazy v-slot="{ isVisible }" ref-name="homeSbContent3">
        <LazySbContent
          v-if="proSolutions && isVisible"
          :content="proSolutions"
          :imgcls="true"
          class="hp-pro-solutions"
        />
      </ComponentLazy>
    </div>
    <LazyMkpAlertsConfirmModal v-if="$route.query.showMkpAlertDeleteSuccess" />
  </div>
</template>

<script setup>
import { useDynamicContent } from '@/composables/useDynamicContent'
import { useDynamicContentStore } from '@/stores/dynamicContent'
import { useClubStore } from '@/stores/club'
import { useUserStore } from '@/stores/user'
import { useEmitterStore } from '@/stores/emitter'

definePageMeta({
  layout: computed(() => {
    const clubStore = useClubStore()
    return clubStore.layout
  }),
  auth: false,
})

const { homeHead } = useHomeMetaData()
const { head, meta, schema } = homeHead()
useHead(head)
useSeoMeta(meta)
useSchemaOrg(schema)

const { getDynamicContent } = useDynamicContent()
const dynamicContentStore = useDynamicContentStore()
const userStore = useUserStore()
const { tld } = useCurrentLocale()
const config = useRuntimeConfig()
const news = computed(() => dynamicContentStore.getNews)
const homeBanner = computed(() => dynamicContentStore.getHomeBanner(userStore))
const featuredSellers = computed(() => dynamicContentStore.getFeaturedSellers)
const featuredBrands = computed(() => dynamicContentStore.getFeaturedBrands)
const seoContent1 = computed(() => dynamicContentStore.getHomeContent('seo-content-1'))
const seoContent2 = computed(() => dynamicContentStore.getHomeContent('seo-content-2'))
const proSolutions = computed(() => dynamicContentStore.getHomeContent('pro-solutions'))
const featuredProducts = computed(() =>
  dynamicContentStore.getFeaturedProducts?.map(ref => (ref ? useReference(ref) : null)).filter(x => x)
)
const featuredSeasonalSelections = computed(() => dynamicContentStore.getFeaturedSeasonalSelections)
const featuredSeasonalSelectionsMenu = computed(() => dynamicContentStore.getFeaturedSeasonalSelectionsMenu)
const bestSellersReferences = computed(() =>
  bestSellers.value?.map(ref => (ref ? useReference(ref) : null)).filter(x => x)
)
const newProductsReferences = computed(() =>
  newProducts.value?.map(ref => (ref ? useReference(ref) : null)).filter(x => x)
)
// const mainSlides = computed(() => dynamicContentStore.getMainSlides)
// const featuredInspirations = computed(() => dynamicContentStore.getFeaturedInspirations)
const bestSellers = ref([])
const bestSellersCount = ref(0)
const newProducts = ref([])
const newProductsCount = ref(0)

const { request, isLoading } = useApiRequest()

try {
  const reqs = [
    request({
      req: useReferenceApi().featured(),
    }),
    getDynamicContent({
      contents: [
        'homeBanners',
        'news',
        'homeContent',
        'featuredSeasonalSelections',
        // 'featuredinspirations',
        'featuredSellers',
        config.public.STORYBLOK_ENV === 'production' ? 'FeaturedBrand' : null,
      ],
    }),
  ]
  if (config.public.STORYBLOK_ENV !== 'production') {
    // Split in multiples queries hack because too much drafts brands (60+) blocking banners in dev/qa since stories results are limited to 100
    reqs.push(
      getDynamicContent({
        contents: ['FeaturedBrand'],
      })
    )
    reqs.push(
      getDynamicContent({
        contents: ['news'],
      })
    )
  }
  const [featuredProductsResults] = await Promise.allSettled(reqs)
  dynamicContentStore.setFeaturedProducts(featuredProductsResults.value?.references || [])
} catch (e) {
  console.error(e)
}

onMounted(() => {
  isLoading.value = false
  const dynamicContentStore = useDynamicContentStore()
  dynamicContentStore.fetchCounters()
  fetchCarousels()
  const emitter = useEmitterStore()
  const { analytics, pageTemplate } = useAnalytics()
  analytics.sendScreen(pageTemplate.HOMEPAGE)
  const route = useRoute()
  if (route.query.showMkpAlertDeleteSuccess) {
    setTimeout(() => {
      emitter.emit('modalOpen_deleteMkpAlertModal', 'deleteMkpAlertModal')
    }, 500)
  }
})
const fetchCarousels = async () => {
  const params = { perPage: 6, page: 1 }
  const [bestSellersResults, newProductsResults] = await Promise.allSettled([
    request({
      req: useReferenceApi().bestSellers(params),
    }),
    request({
      req: useReferenceApi().fresh(params),
    }),
  ])
  bestSellers.value = bestSellersResults?.value?.references || []
  bestSellersCount.value = bestSellersResults?.value?.total || 0
  newProducts.value = newProductsResults?.value?.references || []
  newProductsCount.value = newProductsResults?.value?.total || 0
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/components/custom-carousel';

.hp {
  position: relative;
  width: 100%;

  @include mq($mq-sm) {
    width: 100%;
    left: 0;
    padding: 0 $spacing-md;
  }

  .section {
    padding: 100px 0;
  }
  .home-padding-top {
    padding-top: $spacing-xxl;
    @include mq($mq-sm) {
      padding-top: $spacing-md;
    }
  }
  &-pro-solutions {
    h1 {
      font-size: pxToRem(48px);
      @include mq($mq-md) {
        font-size: pxToRem(30px);
      }
    }
    .sb-fullwidth-banner {
      padding: 0;
    }
    .inner-container.fad {
      max-width: 100%;
      padding: 0;
    }
    .sb-cta-groups {
      margin: 0;
    }
    @include mq($mq-sm) {
      .sb-fullwidth-banner-flex {
        flex-direction: column;
      }
    }
  }
  .carousel-wrapper {
    min-height: 90px;
  }
}

.height-cls-desktop {
  &-featureds {
    height: 321px;
    @include mq($mq-xs) {
      @media (orientation: portrait) {
        height: 460px;
      }
    }
  }

  &-merchants {
    height: 341px;
  }
}

.height-cls-mobile {
  &-featureds {
    height: 306px;
  }
  &-merchants {
    height: 376px;
  }
}
</style>
